import { configureStore } from '@reduxjs/toolkit'
import authSlice from './slices/authSlice'
import componentStateSlice from './slices/componentStateSlice'
import permissionsSlice from './slices/permissionsSlice'


export const store = configureStore({
	reducer: {
		auth: authSlice,
		componentState: componentStateSlice,
		permissions: permissionsSlice
	},
	devTools: true,
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch