import NextNprogress from "nextjs-progressbar";
import React from "react";

export interface LoadingBarProps {
  className?: string;
}

export const LoadingBar = (props: LoadingBarProps) => {
  return (
    <NextNprogress
      color="#56a846"
      startPosition={0.8}
      stopDelayMs={100}
      height={2}
    />
  );
};
