import { Permission } from "@/lib/features/permissions/model/permissions.model";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MyAbility } from "./../../lib/features/abilities/model/actions/my-abilities.model";
import { RootState } from "./../store";

interface PermissionsState {
  permissions: Permission[];
  abilities: MyAbility[];
}

const initialState: PermissionsState = {
  permissions: [],
  abilities: null,
};

const permissionsSlice = createSlice({
  name: "permissions",
  initialState,
  reducers: {
    setPermissions: (state, action: PayloadAction<Permission[]>) => {
      state.permissions = action.payload;
    },
    setAbilities: (state, action: PayloadAction<MyAbility[]>) => {
      state.abilities = action.payload;
    },
  },
  extraReducers: {},
});

export const permissionsSelector = (state: RootState) =>
  state.permissions.permissions;

export const abilitiesSelector = (state: RootState) =>
  state.permissions.abilities;

export const { setPermissions, setAbilities } = permissionsSlice.actions;

export default permissionsSlice.reducer;
