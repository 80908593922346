import { LoadingBar } from "@/components/Shared/LoadingBar/LoadingBar";
import { ProjectSetupProps } from "@/components/Shared/ProjectSetup/ProjectSetup";
import { CaslProvider } from "@/components/Shared/utils/CaslProvider/CaslProvider";
import { queryClient } from "@/lib/react-query/client";
import { skSkIntl } from "@/lib/utils/sk_SK";
import { store } from "@/store/store";
import "@ant-design/pro-card/dist/card.css";
import "@ant-design/pro-descriptions/dist/descriptions.css";
import "@ant-design/pro-form/dist/form.css";
import "@ant-design/pro-layout/dist/layout.css";
import "@ant-design/pro-list/dist/list.css";
import { IntlProvider } from "@ant-design/pro-table";
import "@ant-design/pro-table/dist/table.css";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { ConfigProvider } from "antd";
import skSK from "antd/lib/locale/sk_SK";
import { FlagsmithProvider } from "flagsmith-react";
import "moment/locale/sk";
import { appWithTranslation } from "next-i18next";
import dynamic from "next/dynamic";
import React from "react";
import { QueryClientProvider } from "react-query";
import { Provider } from "react-redux";
import "../styles/atw.css";
import "../styles/result-light.css";

BigInt.prototype["toJSON"] = function () {
  return this.toString();
};

const theme = extendTheme({
  colors: {
    primary: {
      "50": "#EFF8ED",
      "100": "#D1EACD",
      "200": "#B4DCAC",
      "300": "#97CF8C",
      "400": "#79C26B",
      "500": "#5CB44B",
      "600": "#4A903C",
      "700": "#376C2D",
      "800": "#25481E",
      "900": "#12240F",
    },
  },
});

const AppDynamic = dynamic<any>(
  () => import("../components/App").then((mod) => mod.App),
  {
    ssr: false,
  }
);

const ProjectSetupDynamic = dynamic<ProjectSetupProps>(
  () =>
    import("../components/Shared/ProjectSetup/ProjectSetup").then(
      (mod) => mod.ProjectSetup
    ),
  {
    ssr: false,
  }
);

function App({ Component, pageProps }) {
  return (
    <Provider store={store}>
      <ChakraProvider theme={theme}>
        <IntlProvider
          value={{
            intl: skSkIntl,
            valueTypeMap: {},
          }}
        >
          <FlagsmithProvider
            environmentId={process.env.NEXT_PUBLIC_FLAGSMITH_ENV_ID}
          >
            <ConfigProvider locale={skSK}>
              <LoadingBar />
              <CaslProvider>
                <QueryClientProvider client={queryClient}>
                  <ProjectSetupDynamic />
                  <AppDynamic>
                    <Component {...pageProps} />
                  </AppDynamic>
                </QueryClientProvider>
              </CaslProvider>
            </ConfigProvider>
          </FlagsmithProvider>
        </IntlProvider>
      </ChakraProvider>
    </Provider>
  );
}

export default appWithTranslation(App);
