import dot from "dot-object";

export interface ProTableTranslation {
  moneySymbol: string;
  form: {
    lightFilter: {
      more: string;
      clear: string;
      confirm: string;
      itemUnit: string;
    };
  };
  tableForm: {
    search: string;
    reset: string;
    submit: string;
    collapsed: string;
    expand: string;
    inputPlaceholder: string;
    selectPlaceholder: string;
  };
  alert: {
    clear: string;
    selected: string;
    item: string;
  };
  pagination: {
    total: {
      range: string;
      total: string;
      item: string;
    };
  };
  tableToolBar: {
    leftPin: string;
    rightPin: string;
    noPin: string;
    leftFixedTitle: string;
    rightFixedTitle: string;
    noFixedTitle: string;
    reset: string;
    columnDisplay: string;
    columnSetting: string;
    fullScreen: string;
    exitFullScreen: string;
    reload: string;
    density: string;
    densityDefault: string;
    densityLarger: string;
    densityMiddle: string;
    densitySmall: string;
  };
  stepsForm: {
    next: string;
    prev: string;
    submit: string;
  };
  editableTable: {
    action: {
      save: string;
      cancel: string;
      delete: string;
    };
  };
  switch: {
    open: string;
    close: string;
  };
}

export const tableSlovakTranslation: any = {
  moneySymbol: "€",
  form: {
    lightFilter: {
      more: "Viac",
      clear: "Vyčistiť",
      confirm: "Potvrdiť",
      itemUnit: "Záznamov",
    },
  },
  tableForm: {
    search: "Vyhľadávať",
    reset: "Reset",
    submit: "Potvrdiť",
    collapsed: "Viac",
    expand: "Menej",
    inputPlaceholder: "Prosím zadajte",
    selectPlaceholder: "Prosím vyberte ",
  },
  alert: {
    clear: "Clear",
    selected: "Selected",
    item: "Item",
  },
  pagination: {
    total: {
      range: "záznamy",
      total: "z",
      item: "záznamov",
    },
  },
  tableToolBar: {
    leftPin: "Pripnúť dolava",
    rightPin: "Pripnúť doprava",
    noPin: "Nepripnuté",
    leftFixedTitle: "Pripnuté doľava",
    rightFixedTitle: "Pripnuté doprava",
    noFixedTitle: "Nepripnuté",
    reset: "Resetovať",
    columnDisplay: "Stĺpce",
    columnSetting: "Nastavenia",
    fullScreen: "Celá obrazovka",
    exitFullScreen: "Zrušiť celú obrazovku",
    reload: "Refreshnúť",
    density: "Hustota",
    densityDefault: "Základná",
    densityLarger: "Hrubšia",
    densityMiddle: "Stredná",
    densitySmall: "Kompaktná",
  },
  stepsForm: {
    next: "Ďalej",
    prev: "Predchádzajúca",
    submit: "Potvrdiť",
  },
  editableTable: {
    action: {
      save: "Uložiť",
      cancel: "Zrušiť",
      delete: "Vymazať",
    },
  },
  switch: {
    open: "otvoriť",
    close: "zavrieť",
  },
};

export const skSkIntl = {
  locale: "sk-SK",
  getMessage: (id: string, defaultTranslation: string) => {
    return dot.pick(id, tableSlovakTranslation);
  },
};
