import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface SetComponentStatePayload {
  componentName: string;
  settings: any;
}

const componentStateSlice = createSlice({
  name: "componentState",
  initialState: {
    settings: {},
  },
  reducers: {
    setComponentState: (
      state,
      action: PayloadAction<SetComponentStatePayload>
    ) => {
      state.settings[action.payload.componentName] = action.payload.settings;
    },
  },
});

export const getComponentStateSelector = (componentName: string) => (state) =>
  state.componentState.settings[componentName];

export const { setComponentState } = componentStateSlice.actions;

export default componentStateSlice.reducer;