import { generateCaslAbilityFromStrapiPermissionsAndAbilities } from "@/lib/utils/strapiPermissionsToCaslAbility";
import {
  abilitiesSelector,
  permissionsSelector,
} from "@/store/slices/permissionsSlice";
import { Ability } from "@casl/ability";
import { createContextualCan } from "@casl/react";
import { createContext, useMemo } from "react";
import { useSelector } from "react-redux";

export const AbilityContext = createContext(null);
export const Can = createContextualCan(AbilityContext.Consumer);

export const CaslProvider = ({ children }) => {
  const permissions = useSelector(permissionsSelector);
  const abilities = useSelector(abilitiesSelector);

  const ability = useMemo(() => {
    // If permissions are not populated return early
    // this prevents partial loading of permissions and subsequent inaccurate UI render
    if (permissions.length === 0 || abilities === null) {
      return new Ability([]);
    }

    const generatedAbility =
      generateCaslAbilityFromStrapiPermissionsAndAbilities(
        permissions,
        abilities
      );


    return generatedAbility;
  }, [permissions, abilities]);

  return (
    <AbilityContext.Provider value={ability}>
      {children}
    </AbilityContext.Provider>
  );
};
