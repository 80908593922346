import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AuthReturn, AuthUser } from './../../lib/features/auth/model/auth.model';
import { RootState } from './../store';

interface AuthState {
	user: AuthUser
	jwt: string;
}

const initialState: AuthState = {
	user: null,
	jwt: null
}

const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		setAuth: (state, action: PayloadAction<AuthReturn>) => {
			state.user = action.payload.user;
			state.jwt = action.payload.jwt
		},
		deAuthUser: (state) => {
			state.user = null;
			state.jwt = null;
		},
	},
	extraReducers: {
	},
});

export const userSelector = (state: RootState) => state.auth.user;
export const jwtSelector = (state: RootState) => state.auth.jwt;

export const { setAuth, deAuthUser } = authSlice.actions;

export default authSlice.reducer;
