import { Ability, createAliasResolver, defineAbility } from "@casl/ability";
import { Permission } from "../features/permissions/model/permissions.model";
import { MyAbility } from "./../features/abilities/model/actions/my-abilities.model";

export interface StrapiAbilityTargetDefinition {
  id: number;
  type: string;
}

const getStrapiAbilityTargetDefinition = (
  ability: MyAbility
): StrapiAbilityTargetDefinition => {
  return {
    id: ability.targetModelId,
    type: ability.targetModelName,
  };
};

/**
 * Permissions to be skipped since these should only be inferred from abilities
 * because their real permission is <permissionName>All
 */
const rolePermissionsToBeSkipped = ["update", "findone", "delete"];

/**
 * These are models which do not have the <actionName>All action
 * and therefore need to be handled differently, because there the action
 * actually means what it means
 */
const modelsWithOriginalPermissionStructure = [
  "user",
  "ability",
  "audit-log-record",
  "watch-list",
  "approval-process",
];

export const generateCaslAbilityFromStrapiPermissionsAndAbilities = (
  permissions: Permission[],
  abilities: MyAbility[]
): Ability => {
  const resolveAction = createAliasResolver({
    updateall: ["update"],
    deleteall: ["delete"],
    findall: ["find"],
    findoneall: ["findone"],
  });

  const ability = defineAbility(
    (can, cannot) => {
      // Define rules from role permissions
      for (const permission of permissions) {
        // Handle the model with basic rights without skipping the default premissions
        // since there are no abilities for this model and therefore the permissions
        // can't be infered from them, othwer wise skip permissions that should only be infered
        // from abilities
        if (
          rolePermissionsToBeSkipped.includes(permission.action) &&
          !modelsWithOriginalPermissionStructure.includes(permission.controller)
        ) {
          continue;
        }

        if (permission.controller === "received-mail") {
          console.log(permission);
        }
        if (permission.enabled) {
          can(permission.action, permission.controller);
        } else {
          // cannot(permission.action, permission.controller);
        }
      }

      // Define rules from abilities
      for (const myAbility of abilities) {
        try {
          const { type, id } = getStrapiAbilityTargetDefinition(myAbility);

          can(myAbility.action, type, {
            id: `${id}`,
          });
        } catch (e) {
          console.log("ability not able to be converted", ability);
        }
      }
    },
    { resolveAction }
  ) as Ability;

  return ability;
};
